<template>
  <div class="home">
    <van-nav-bar
    v-if="navTitle"
    class="c-nav-bar"
      :title="navTitle"
      :left-text="leftText"
      left-arrow
      @click-left="go"
    />
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "emptyLayout",
  data() {
    return {
      leftText:"返回",
    };
  },
  computed:{
    navTitle(){
      return !this.$route.meta.hideNav&&this.$route.meta.title
    }
  },
  mounted(){
    console.info("EmptyLayout mounted:",this.getQueryVariable("back"))
    if (this.getQueryVariable("back")=="home") {
      this.leftText = "首页"
    }
  },
  methods:{
    go(){
      if (this.getQueryVariable("back")=="home"){
        this.$router.push("/home")
      }else {
        this.$router.back(-1)
      }
    },
    getQueryVariable(variable)
    {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
      }
      return(false);
    }
  }
};
</script>
<style lang="less" scoped>
.c-nav-bar{
  margin-bottom: 10px;
  position: sticky;
  top: 0;
}
</style>
